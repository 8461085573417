<template>
	<div class="userleft">
		<h3 @click="touserurl('/user/my')">我的趣游</h3>
		<ul>
			<li v-for="(item,i) in usernav" :key="i">
				<!-- <p @click="touserurl(item.to)">
					<strong>{{item.name}}</strong>
				</p>
				<p v-for="(it,n) in item.childen"
				 @click="touserurl(it.to)">
					{{it.name}}
				</p> -->
				 <router-link :to="item.to" tag="p">
					 <strong>{{item.name}}</strong>
				 </router-link>
				 <router-link :to="it.to"
				 tag="p" v-for="(it,n) in item.childen" :key="n" class="link">
				 	{{it.name}}
           
           
                <template v-if="it.name=='订单信息' && count.Count>0">
                  <div class="dian"> {{count.Count}}</div>
                </template>
                <template v-if="it.name=='系统通知' && count.Count2>0">
                <div class="dian">{{count.Count2}}</div>
                </template>
            
           
				 </router-link>
			</li>
		</ul>
	</div>
</template>

<script>

export default {

  data () {
    return {
      usernav: [
        {
          name: '我的订单',
          to: '/user/order',
          childen: []
        }, {
          name: '我的卡包',
          to: '',
          childen: [{
            name: '优惠券',
            to: '/user/coupons'
          }, {
            name: '奖励积分',
            to: '/user/integral'
          },{
            name: '等级积分',
            to: '/user/djintegral'
          },
          //  {
          //   name: '体验卡',
          //   to: '/user/mycard'
          // }
          ]
        },
        //  {
        //   name: '我的游记',
        //   to: '/user/mytravel',
        //   childen: []
        // }, 
        // {
        //   name: '我的问答',
        //   to: '/user/myquestion',
        //   childen: []
        // },
        {
          name: '我的点评',
          to: '/user/mycomment',
          childen: []
        }, {
          name: '我的收藏/关注',
          to: '/user/collection',
          childen: []
        },
        // {
        //   name: '我的定制',
        //   to: '/user/mycustom',
        //   childen: []
        // },
         {
          name: '个人信息',
          to: '',
          childen: [
            {
              name: '个人资料',
              to: '/user/personinfo'
            }, {
              name: '常用出行人',
              to: '/user/userlist'
            },
            //  {
            //   name: '常用开票信息',
            //   to:'/user/userticket'
            // }, {
            //   name: '常用地址',
            //   to: '/user/useradress'
            // }
          ]
        }, {
          name: '消息中心',
          to: '',
          childen: [
            {
              name: '订单信息',
              to: '/user/ordermsg?id=1'
            }, {
              name: '系统通知',
              to: '/user/ordermsg?id=2'
            }
          ]
        }
      ],
      count:{
        Count:0,
        Count2:0
      }
    }
  },
  created () {
   
  },
  methods: {
    touserurl (url) {
      this.$router.push({ path: url })
    },
    
  }
}
</script>

<style lang="scss" scoped="scoped">

.userleft{
	width: 150px;
	background: #ffffff;
	border-radius: 6px;
	text-indent: 15px;
	padding: 10px 0;
	h3{
		font-size: 17px;
		height: 35px;
		font-weight: 500;
		color: #ff7c00;
		line-height: 38px;
		cursor: pointer;
	}
	li{
		border-bottom: 1px solid #e6e6e6;
		padding: 5px 0;
		p{
			cursor: pointer;
			font-size: 12px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			text-align: left;
			color: #666666;
			line-height: 30px;
			strong{
				color: #333;
			}
		}
		p:hover{
			color: #ff7c00;
		}
		p:hover strong{
			color: #ff7c00;
		}
	}
	li:last-child{
		border: none;
	}
	.router-link-exact-active{
	  color: #FF7C00;

	}
  .link{
    position: relative;
     .dian{
      position: absolute;
      top: 7px;
      left: 70px;
      background-color: red;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      color: #ffffff;
      font-size: 10px;
      text-align: center;
      line-height: 20px;
      	text-indent: 0;
    }
  }
 
}

</style>
