<template>
	<div>
		<myheader></myheader>
		<div class="usermain">
			<div class="main">
				<userleft ref="userleft"></userleft>
				<div class="userright">
					  <router-view/>
				</div>
			</div>
		</div>

		<myfooter></myfooter>
		<myright></myright>
	</div>

</template>

<script>
import myheader from '@/components/header.vue'
import myfooter from '@/components/footer.vue'
import myright from '@/components/right.vue'
import userleft from '@/components/userleft.vue'
import {messageCountAPI} from "@/api/user"
export default {

  components: {
    myheader, myfooter, myright, userleft
  },
  data () {
    return {
		count:{}
    }
  },
  created () {
	  this.gettoken()
  },
  methods: {
	  gettoken(){
		  var token=localStorage.getItem("token")
		 if (token==null) {
			 this.$message("请先登录")
			 this.$router.push({ path: "/index" });
		 } 
	  },
	messageCount(){
		messageCountAPI().then(res=>{
			this.$refs.userleft.count=res
			this.count=res
		})
	}
  }
}
</script>

<style lang="scss" scoped="scoped">
.usermain{
	background-color: #f9f9f9;
	padding-bottom: 20px;
	padding-top: 20px;
	.main{
		width: 1200px;
		margin:0px auto;
		display: flex;

		.userright{
			margin-left: 30px;
			flex: 1;
			min-height: 570px;

			border-radius: 6px;

		}
	}

}

</style>
